<template>
  <dialog-form
      :title="`${$t('moveNode')} ${from.networkUnit ? from.networkUnit.name : ''}`"
      :translateTitle="false"
      :visible="visible"
      @submit="submitForm"
      @cancel="$emit('cancel')"
  >
      <network-tree
          v-loading="loading"
          container-shadow="never"
          container-body-style="padding: 0"
          container-class="network-modal-tree__module"
          v-if="visible"
          @node-click="selectNode"
          @toggle-loading="toggleLoading"
          ref="treeComp"
      >
      </network-tree>

    <div slot="footer">
    <div v-if="errors" class="mb-1 ods-form-item__error" style="position:relative;text-align: left">
      {{$t(errors)}}
    </div>
    </div>

  </dialog-form>
</template>

<script>
import NetworkNodeService from '@/services/NetworkNode'
import handlePromise from '@/utils/promise'
import DialogForm from '@/custom-components/DialogForm'
import NetworkTree from '@/custom-components/Network/Tree'

export default {
  name: 'MoveNode',
  components: {
    DialogForm,
    NetworkTree
  },
  props: {
    visible: Boolean,
    from: Object
  },
  data () {
    return {
      loading: false,
      destination: null,
      errors: null,
      props: {
        children: 'children',
        label: 'name',
        isLeaf: 'leaf'
      }
    }
  },
  watch: {
    visible: function () {
      if (this.visible) {
        this.destination = null
        this.errors = null
      }
    }
  },
  methods: {
    toggleDialog (action = 'cancel') {
      this.$emit(action)
    },
    toggleLoading () {
      this.loading = !this.loading
    },
    selectNode (node) {
      this.destination = node.id
    },
    async loadTreeNode (node, resolve) {
      if (node.level === 0) this.toggleLoading()

      const id = node.level === 0 ? null : node.data.id
      const [error, response, data] = await handlePromise(NetworkNodeService.getNodeTree(id))

      if (node.level === 0) this.toggleLoading()
      if (response.ok) {
        resolve(data.map(item => ({
          id: item.id,
          name: item.networkUnit.name,
          isLeaf: false,
          data: item,
          children: [{}]
        })))
      } else {
        this.$store.commit('settings/toggleAlert', error)
        resolve([])
      }
    },
    async submitForm () {
      if (!this.destination) this.errors = 'selectDestinationNode'
      if (this.destination && this.from.id !== this.destination) {
        this.toggleLoading()

        const [error, response] = await handlePromise(NetworkNodeService.updateNode(this.from.id,
          { destination: this.destination }
        ))
        this.toggleLoading()
        if (!response.ok) return this.$store.commit('settings/toggleAlert', error)
        this.toggleDialog('reload')
      }
    }
  }
}
</script>
