<template>
  <dialog-form
      title="newChild"
      :visible="visible"
      @submit="submitForm"
      @cancel="toggleDialog"
  >
    <ods-form
        :model="form"
        ref="form"
        v-loading="loading"
    >
      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('name')" prop="name" :rules="[errors.required]">
            <ods-input v-model="form.name"></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('originSystemId')" prop="originSystemId" :rules="[errors.required]">
            <ods-input v-model="form.originSystemId"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('technicalLosses')" prop="technicalLosses" :rules="[errors.required]">
            <ods-input v-model="form.technicalLosses"></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('networkElement')" prop="networkElement" :rules="[errors.required]">
            <ods-select v-model="form.networkElement" remote>
              <ods-option
                  v-for="item in networkElements"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </ods-option>
            </ods-select>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('latitude')" prop="lat" :rules="[errors.required]">
            <ods-input v-model="form.lat"></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('longitude')" prop="long" :rules="[errors.required]">
            <ods-input v-model="form.long"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col
            :md="12"
            v-for="(item, index) in form.attributes"
            :key="item.id">
          <ods-form-item :label="item.name" :prop="'attributes.' + index + '.value'"
                         :rules="item.mandatory ? [errors.required] : []">
            <ods-input
                v-model="item.value"
                v-if="item.type === 'NUMBER' && !item.lov"
                type="number">
            </ods-input>
            <ods-input
                v-model="item.value"
                v-if="item.type === 'STRING' && !item.lov">
            </ods-input>
            <ods-select v-model="item.value" v-if="item.lov">
              <ods-option
                  v-for="value in item.lov.lovValues"
                  :key="value.id"
                  :label="value.value"
                  :value="value.id"
              >
              </ods-option>
            </ods-select>
          </ods-form-item>
        </ods-col>
      </ods-row>
    </ods-form>
  </dialog-form>
</template>

<script>
import DialogForm from '@/custom-components/DialogForm'
import errors from '@/config/InputErrors'
import NetworkNodeService from '@/services/NetworkNode'
import NetworkElementService from '@/services/NetworkElement'
import handlePromise from '@/utils/promise'
import attributes from '@/config/data/Attributes'

export default {
  name: 'AddChild',
  components: {
    DialogForm
  },
  props: {
    visible: Boolean,
    parent: String
  },
  data () {
    return {
      form: {
        attributes
      },
      loading: false,
      errors,
      networkElements: []
    }
  },
  watch: {
    visible: function () {
      if (this.visible) {
        this.getNetworkElements()
      }
    }
  },
  methods: {
    async submitForm () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.loading = true

          const data = {
            parent: this.parent,
            networkUnit: {
              name: this.form.name,
              originSystemId: this.form.originSystemId,
              technicalLosses: this.form.technicalLosses,
              networkElement: this.form.networkElement,
              geometry: {
                type: 'Point',
                coordinates: [
                  this.form.lat,
                  this.form.long
                ]
              }
            }
          }

          this.form.attributes
            .filter(item => !!item.value)
            .map(item => {
              data.networkUnit[item.name] = item.value
            })

          const [error, response] = await handlePromise(NetworkNodeService.saveNode(data))
          this.loading = false
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
          this.toggleDialog('reload')
        }
      })
    },
    toggleDialog (action = 'cancel') {
      if (this.visible && this.$refs['form']) {
        this.$refs['form'].resetFields()
        this.form = {
          attributes
        }
      }
      this.$emit(action)
    },
    async getNetworkElements () {
      const [, response, data] = await handlePromise(NetworkElementService.getElements({ limit: 0 }))
      if (response.ok) {
        this.networkElements = data.data
      }
    },
    async getAttributes (networkElId) {
      this.loading = true

      const [error, response, data] = await handlePromise(
        NetworkElementService.getElementAttributes(networkElId, { limit: 0 }))
      this.loading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.form.attributes = data.data
    }
  }
}
</script>
