<template>
  <section v-loading="loading">
    <ods-row :gutter="20">
      <ods-col :md="7">
        <network-tree
            @node-click="searchByNetworkUnit"
            @toggle-loading="toggleLoading"
        >
        </network-tree>
      </ods-col>

      <ods-col :md="17">
        <ods-module :header="$t('measurementLoading')">
          <ods-form :model="search" ref="form">
            <ods-row :gutter="20" type="flex" align="center">
              <ods-col :md="10">
                <ods-form-item :label="$t('number')" prop="serialNumber"
                               :rules="[errors.required]">
                  <ods-input v-model="search.serialNumber"></ods-input>
                </ods-form-item>
              </ods-col>
              <ods-col :md="10">
                <ods-form-item :label="$t('period')" prop="period">
                  <ods-select v-model="search.period" placeholder="" remote clearable>
                    <ods-option
                        v-for="item in periods"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </ods-option>
                  </ods-select>
                </ods-form-item>
              </ods-col>

              <ods-col :md="4">
                <ods-button type="primary" @click="searchBySerialNumber">
                  {{$t('search')}}
                </ods-button>
              </ods-col>
            </ods-row>
          </ods-form>
        </ods-module>

        <loading-readings
            v-if="searchEnabled && selectedMeter"
            :periods="periods"
            :activePeriod="activePeriod"
            :meter="selectedMeter"
            :data="readings"
            @reload-data="searchMeterReadings"
            :hasPermission="hasPermission"
        />
      </ods-col>
    </ods-row>
  </section>
</template>

<script>
import MeterReadingService from '@/services/MeterReading'
import MeterService from '@/services/Meter'
import PeriodService from '@/services/Period'
import handlePromise from '@/utils/promise'
import NetworkTree from '@/custom-components/Network/Tree'
import LoadingReadings from './Reading'
import errors from '@/config/InputErrors'
import { mapState } from 'vuex'

export default {
  name: 'MeasurementLoading',
  components: {
    NetworkTree,
    LoadingReadings
  },
  computed: {
    ...mapState({
      userRole: state => state.userRoles.data
    })
  },
  mounted () {
    this.fetchPeriods()
    this.fetchActivePeriods()
    this.handlePermission()
  },
  data () {
    return {
      hasPermission: true,
      loading: false,
      searchEnabled: false,
      selectedMeter: null,
      search: {},
      periods: [],
      activePeriod: {},
      readings: [],
      errors
    }
  },
  methods: {
    async fetchPeriods () {
      const [error, response, responseData] = await handlePromise(PeriodService.getPeriods({ limit: 0 }))
      if (response.ok) this.periods = responseData.data
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
    },
    async fetchActivePeriods () {
      const [, response, responseData] = await handlePromise(PeriodService.getActivePeriods({ limit: 0 }))
      if (response.ok) this.activePeriod = responseData
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t('activePeriodsNotFound'))
    },
    async searchByNetworkUnit ({ data }) {
      this.toggleLoading()
      this.selectedMeter = null
      const [error, response, responseData] = await handlePromise(MeterService.getMeterBySearch({
        networkUnit: data.networkUnit.id
      }))
      if (!response.ok) {
        this.toggleLoading()
        return this.$store.commit('settings/toggleAlert', this.$t(error))
      }
      this.selectedMeter = responseData
      this.search = {
        serialNumber: responseData.serialNumber
      }
      this.$refs['form'].validateField('serialNumber')
      setTimeout(() => {
        this.searchMeterReadings()
      }, 100)
    },
    async searchBySerialNumber () {
      this.$refs['form'].validate(async (valid) => {
        this.searchEnabled = false
        if (valid) {
          this.toggleLoading()
          const [error, response, responseData] = await handlePromise(MeterService.getMeterBySearch({
            serialNumber: this.search.serialNumber
          }))
          if (!response.ok) {
            this.toggleLoading()
            return this.$store.commit('settings/toggleAlert', this.$t(error))
          }
          this.selectedMeter = responseData
          this.searchMeterReadings()
        }
      })
    },
    async searchMeterReadings () {
      this.$refs['form'].validate(async (valid) => {
        this.searchEnabled = false
        if (!valid && this.loading) this.toggleLoading()

        if (valid) {
          if (!this.loading) this.toggleLoading()
          const params = { limit: 0 }
          if (this.search.period === undefined && this.activePeriod.id) {
            params['filters[period]'] = this.activePeriod.id
            const [error, response, data] = await handlePromise(MeterReadingService.getMeterReadings(this.selectedMeter.id, params))
            if (this.loading) this.toggleLoading()
            if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
            this.searchEnabled = true
            this.readings = data
          }
          if (this.search.period) {
            params['filters[period]'] = this.search.period
            const [error, response, data] = await handlePromise(MeterReadingService.getMeterReadings(this.selectedMeter.id, params))
            if (this.loading) this.toggleLoading()
            if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
            this.searchEnabled = true
            this.readings = data
          }
          if (this.search.period === undefined && !this.activePeriod.id) {
            const [error, response, data] = await handlePromise(MeterReadingService.getMeterReadings(this.selectedMeter.id, params))
            if (this.loading) this.toggleLoading()
            if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
            this.searchEnabled = true
            this.readings = data
          }
        }
      })
    },
    toggleLoading () {
      this.loading = !this.loading
    },
    handlePermission () {
      let rolesArray = Object.values(this.userRole)
      rolesArray.map(role => {
        if (role === 'ROLE_OPERATOR') {
          this.hasPermission = false
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_SUPER_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_MANAGER') {
              this.hasPermission = true
            }
          })
        }
      })
    }
  }
}
</script>
