<template>
  <section>
    <ods-form :model="model" ref="nodeForm" v-loading="formLoading" :disabled="!hasPermission">
      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('networkElement')" prop="networkElement">
            <ods-input v-model="model.networkElement" disabled></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('originSystemId')" prop="originSystemId">
            <ods-input v-model="model.originSystemId" disabled></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('name')" prop="name">
            <ods-input v-model="model.name" :disabled="model.disabled"></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('technicalLossesPercent')" prop="technicalLosses">
            <ods-input v-model="model.technicalLosses" :disabled="model.disabled" type="number"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col
            :md="12"
            v-for="(item, index) in model.attributes"
            :key="item.id">
          <ods-form-item :label="item.name" :prop="'attributes.' + index + '.value'"
                         :rules="item.mandatory ? [errors.required] : []">
            <ods-input
                v-model="item.value"
                :disabled="model.disabledAttributes"
                v-if="item.type === 'NUMBER' && !item.lov"
                type="number">
            </ods-input>
            <ods-input
                v-model="item.value"
                :disabled="model.disabledAttributes"
                v-if="item.type === 'STRING' && !item.lov">
            </ods-input>
            <ods-select v-model="item.value" v-if="item.lov" :disabled="model.disabledAttributes">
              <ods-option
                  v-for="value in item.lov.lovValues"
                  :key="value.id"
                  :label="value.value"
                  :value="value.id"
              >
              </ods-option>
            </ods-select>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row
          type="flex"
          justify="center"
          v-if="!model.disabled || (model.attributes && model.attributes.length && !model.disabledAttributes)">
        <ods-button type="primary" @click="submitForm">
          {{$t('save')}}
        </ods-button>
      </ods-row>
    </ods-form>
  </section>
</template>

<script>
import NetworkUnitService from '@/services/NetworkUnit'
import handlePromise from '@/utils/promise'
import errors from '@/config/InputErrors'

export default {
  name: 'EditNode',
  props: {
    model: Object,
    hasPermission: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      formLoading: false,
      errors
    }
  },
  methods: {
    async submitForm () {
      this.$refs['nodeForm'].validate(async (valid) => {
        if (valid) {
          this.formLoading = true

          let data = {}
          if (!this.model.disabled) {
            data = {
              name: this.model.name,
              technicalLosses: this.model.technicalLosses
            }
          }
          if (!this.model.disabledAttributes) {
            this.model.attributes
              .filter(item => !!item.value)
              .map(item => {
                data[item.name] = item.value
              })
          }
          const [error, response] = await handlePromise(NetworkUnitService.updateNetworkUnit(this.model.id, data))
          this.formLoading = false
          if (!response.ok) return this.$store.commit('settings/toggleAlert', error)
        }
      })
    }
  }
}
</script>
