import api from '@/config/Api'
import { formatFormDataBody } from '@/utils/format'

const baseEndpoint = '/network/import-files'
const baseEndpointWater = '/network/import-water-files'

export default {
  importFile (data) {
    return api.post('/network/import-file', formatFormDataBody(data))
  },

  importFileWater (data) {
    return api.post('/network/import-water-file', formatFormDataBody(data))
  },

  getImportFile (id) {
    return api.get(`/network/import-file/${id}`)
  },

  getImportFileWater (id) {
    return api.get(`/network/import-water-file/${id}`)
  },

  getImports (params) {
    return api.get(baseEndpoint, params)
  },

  getImportsWater (params) {
    return api.get(baseEndpointWater, params)
  }
}
