import Vue from 'vue'
import Router from 'vue-router'
import _ from 'lodash'
import storage from '../utils/storage'
import Profile from '../views/Profile'
import Login from '../views/Login'
import Private from '../views/Private'

import UserDashboard from '../views/User/Dashboard'
import UserEdit from '../views/User/Edit'
import UserChangePassword from '../views/User/ChangePassword'

// ENERGY
import BalanceDashboardEnergy from '../views/Energy/Balance/Dashboard'
import BalanceDashboardWater from '../views/Water/Balance/Dashboard'
import BalanceEnergyInput from '../views/Energy/Balance/EnergyInput/Dashboard'
import BalanceEnergyHistorical from '../views/Energy/Balance/Historical/Dashboard'

import StrategicPlanDashboardEnergy from '../views/Energy/EstrategicPlan/Dashboard'
import StrategicPlanNewEnergy from '../views/Energy/EstrategicPlan/Edit'
import StategicPlanAnalisisEnergy from '../views/Energy/EstrategicPlan/Analisis/Dashboard'

import LovDashboardEnergy from '../views/Energy/Lov/Dashboard'
import LovNewEnergy from '../views/Energy/Lov/Edit'

import BrandDashboardEnergy from '../views/Energy/BrandModel/Dashboard'
import BrandDashboardNewEnergy from '../views/Energy/BrandModel/Edit'

import NetworkElementEnergy from '../views/Energy/NetworkElement/Dashboard'
import NetworkElementNewEnergy from '../views/Energy/NetworkElement/Edit'

import NetworkConfigEnergy from '../views/Energy/Network/Dashboard'
import NetworkImportEnergy from '../views/Energy/Import/Dashboard'
import NetworkImportDetailEnergy from '../views/Energy/Import/Detail'

import ManeuversDashboardEnergy from '../views/Energy/Maneuver/Dashboard'
import ManeuverDetailEnergy from '../views/Energy/Maneuver/Detail'

import ReconfigurationDashboardEnergy from '../views/Energy/Reconfiguration/Dashboard'
import NetworkHistoryEnergy from '../views/Energy/NetworkHistory/Dashboard'

import MeterDashboardEnergy from '../views/Energy/MeterManagement/Dashboard'
import MeterImportEnergy from '../views/Energy/MeterImport/Dashboard'
import MeterImportDetailEnergy from '../views/Energy/MeterImport/Detail'

import AreaEnergy from '../views/Energy/AreaDefinition/Dashboard'
import AreaNewEnergy from '../views/Energy/AreaDefinition/Edit'

import PeriodEnergy from '../views/Energy/Period/Dashboard'
import PeriodNewEnergy from '../views/Energy/Period/Edit'

import PurchaseEnergy from '../views/Energy/Purchase/Dashboard'
import PurchaseNewEnergy from '../views/Energy/Purchase/Edit'

import MeasurementEnergy from '../views/Energy/Measurement/Loading/Dashboard'
import MeasurementValidationEnergy from '../views/Energy/Measurement/Validation/Dashboard'
import MeasurementBulkValidationEnergy from '../views/Energy/Measurement/BulkValidation/Dashboard'
import MeasurementAnalisisEnergy from '../views/Energy/Measurement/Analysis/Dashboard'
import MeasurementImportEnergy from '../views/Energy/Measurement/Import/Dashboard'
import MeasurementImportDetailEnergy from '../views/Energy/Measurement/Import/Detail'

import ForecastEnergy from '../views/Energy/Forecast/Loading/Dashboard'
import ForecastValidationEnergy from '../views/Energy/Forecast/Validation/Dashboard'
import ForecastAnalsisEnergy from '../views/Energy/Forecast/Analysis/Dashboard'
import ForecastImportEnergy from '../views/Energy/Forecast/Import/Dashboard'
import ForecastImportDetailEnergy from '../views/Energy/Forecast/Import/Detail'

// WATER

import StategicPlanWater from '../views/Water/EstrategicPlan/Dashboard'
import StrategicPlanNewWater from '../views/Water/EstrategicPlan/Edit'
import StrategicPlanAnalisisWater from '../views/Water/EstrategicPlan/Analisis/Dashboard'

import BalanceWaterInput from '../views/Water/Balance/EnergyInput/Dashboard'
import BalanceWaterHistorical from '../views/Water/Balance/Historical/Dashboard'

import LovWater from '../views/Water/Lov/Dashboard'
import LovNew from '../views/Water/Lov/Edit'

import BrandWater from '../views/Water/BrandModel/Dashboard'
import BrandNewWater from '../views/Water/BrandModel/Edit'

import NetworkElementWater from '../views/Water/NetworkElement/Dashboard'
import NetowrkElementNewWater from '../views/Water/NetworkElement/Edit'
import NetworkWater from '../views/Water/Network/Dashboard'
import NetworkImportWater from '../views/Water/Import/Dashboard'
import NetworkImportDetailWater from '../views/Water/Import/Detail'

import ManeuverWater from '../views/Water/Maneuver/Dashboard'
import ManeuverDetailWater from '../views/Water/Maneuver/Detail'

import ReconfigurationWater from '../views/Water/Reconfiguration/Dashboard'
import NetworkHistoryWater from '../views/Water/NetworkHistory/Dashboard'

import MeterWater from '../views/Water/MeterManagement/Dashboard'
import MeterImportWater from '../views/Water/MeterImport/Dashboard'
import MeterImportDetailWater from '../views/Water/MeterImport/Detail'

import AreaWater from '../views/Water/AreaDefinition/Dashboard'
import AreaNewWater from '../views/Water/AreaDefinition/Edit'

import PeriodWater from '../views/Water/Period/Dashboard'
import PeriodNewWater from '../views/Water/Period/Edit'

import PurchaseWater from '../views/Water/Purchase/Dashboard'
import PurchaseNewWater from '../views/Water/Purchase/Edit'

import MeasurementWater from '../views/Water/Measurement/Loading/Dashboard'
import MeasurementValidationWater from '../views/Water/Measurement/Validation/Dashboard'
import MeasurementBulkValidationWater from '../views/Water/Measurement/BulkValidation/Dashboard'
import MeasurementAnalisisWater from '../views/Water/Measurement/Analysis/Dashboard'
import MeasurementImportWater from '../views/Water/Measurement/Import/Dashboard'
import MeasurementImportDetailWater from '../views/Water/Measurement/Import/Detail'

import ForecastWater from '../views/Water/Forecast/Loading/Dashboard'
import ForecastValidationWater from '../views/Water/Forecast/Validation/Dashboard'
import ForecastAnalisisWater from '../views/Water/Forecast/Analysis/Dashboard'
import ForecastImportWater from '../views/Water/Forecast/Import/Dashboard'
import ForecastImportDetailWater from '../views/Water/Forecast/Import/Detail'

const energyRoutes = [
  {
    path: '/',
    component: Private,
    children: [
      {
        path: '/',
        redirect: '/balance'
      },
      {
        path: '/balance-energy',
        component: BalanceDashboardEnergy
      },
      {
        path: '/balance-water',
        component: BalanceDashboardWater
      },
      {
        path: '/balance',
        name: 'BalanceDashboard',
        component: BalanceDashboardEnergy
      },
      {
        path: '/strategic-plan',
        name: 'BalanceStrategicPlan',
        component: StrategicPlanDashboardEnergy
      },
      {
        path: '/strategic-plan/new',
        name: 'PlanNew',
        component: StrategicPlanNewEnergy
      },
      {
        path: '/strategic-plan/:id',
        name: 'PlanEdit',
        component: StrategicPlanNewEnergy
      },
      {
        path: '/strategic-analysis',
        name: 'StrategicAnalisis',
        component: StategicPlanAnalisisEnergy
      },
      {
        path: '/energy-input',
        name: 'BalanceEnergyInput',
        component: BalanceEnergyInput
      },
      {
        path: '/energy-historical',
        name: 'BalanceEnergyHistorical',
        component: BalanceEnergyHistorical
      },
      {
        path: '/users',
        component: UserDashboard,
        name: 'UserDashboard'
      },
      {
        path: '/users/new',
        name: 'UserNew',
        component: UserEdit
      },
      {
        path: '/users/:id/change-password',
        name: 'UserChangePassword',
        component: UserChangePassword
      },
      {
        path: '/users/:id',
        name: 'UserEdit',
        component: UserEdit
      },
      {
        path: '/lovs',
        name: 'LovDashboard',
        component: LovDashboardEnergy
      },
      {
        path: '/lovs/new',
        name: 'LovNew',
        component: LovNewEnergy
      },
      {
        path: '/lovs/:id',
        name: 'LovEdit',
        component: LovNewEnergy
      },
      {
        path: '/brandMaintenance',
        name: 'BrandDashboard',
        component: BrandDashboardEnergy
      },
      {
        path: '/brandMaintenance/new',
        name: 'BrandNew',
        component: BrandDashboardNewEnergy
      },
      {
        path: '/brandMaintenance/:id',
        name: 'BrandEdit',
        component: BrandDashboardNewEnergy
      },
      {
        path: '/network-elements',
        name: 'NetworkElementDashboard',
        component: NetworkElementEnergy
      },
      {
        path: '/network-elements/:id',
        name: 'NetworkElementEdit',
        component: NetworkElementNewEnergy
      },
      {
        path: '/network-config',
        name: 'NetworkDashboard',
        component: NetworkConfigEnergy
      },
      {
        path: '/import-network',
        name: 'ImportDashboard',
        component: NetworkImportEnergy
      },
      {
        path: '/import-network/:id',
        name: 'ImportDetail',
        component: NetworkImportDetailEnergy
      },
      {
        path: '/maneuver',
        name: 'ManeuverDashboard',
        component: ManeuversDashboardEnergy
      },
      {
        path: '/maneuver/:id',
        name: 'ManeuverDetail',
        component: ManeuverDetailEnergy
      },
      {
        path: '/reconfiguration',
        name: 'ReconfigurationDashboard',
        component: ReconfigurationDashboardEnergy
      },
      {
        path: '/network-history',
        name: 'NetworkHistoryDashboard',
        component: NetworkHistoryEnergy
      },
      {
        path: '/meters-management',
        name: 'MeterManagementDashboard',
        component: MeterDashboardEnergy
      },
      {
        path: '/import-meters',
        name: 'MeterImportDashboard',
        component: MeterImportEnergy
      },
      {
        path: '/import-meters/:id',
        name: 'MeterImportDetail',
        component: MeterImportDetailEnergy
      },
      {
        path: '/areas-definition',
        name: 'AreaDefinitionDashboard',
        component: AreaEnergy
      },
      {
        path: '/areas-definition/new',
        name: 'AreaDefinitionNew',
        component: AreaNewEnergy
      },
      {
        path: '/areas-definition/:id',
        name: 'AreaDefinitionEdit',
        component: AreaNewEnergy
      },
      {
        path: '/periods-maintenance',
        name: 'PeriodDashboard',
        component: PeriodEnergy
      },
      {
        path: '/periods-maintenance/new',
        name: 'PeriodNew',
        component: PeriodNewEnergy
      },
      {
        path: '/periods-maintenance/:id',
        name: 'PeriodEdit',
        component: PeriodNewEnergy
      },
      {
        path: '/purchases',
        name: 'PurchaseDashboard',
        component: PurchaseEnergy
      },
      {
        path: '/purchases/new',
        name: 'PurchaseNew',
        component: PurchaseNewEnergy
      },
      {
        path: '/purchases/:id',
        name: 'PurchaseEdit',
        component: PurchaseNewEnergy
      },
      {
        path: '/measurement-management',
        name: 'MeasurementLoading',
        component: MeasurementEnergy
      },
      {
        path: '/measurement-validation',
        name: 'MeasurementValidation',
        component: MeasurementValidationEnergy
      },
      {
        path: '/measurement-bulk-validation',
        name: 'MeasurementBulkValidation',
        component: MeasurementBulkValidationEnergy
      },
      {
        path: '/measurement-analysis',
        name: 'MeasurementAnalysis',
        component: MeasurementAnalisisEnergy
      },
      {
        path: '/measurement-import',
        name: 'MeasurementImportDashboard',
        component: MeasurementImportEnergy
      },
      {
        path: '/measurement-import/:id',
        name: 'MeasurementImportDetail',
        component: MeasurementImportDetailEnergy
      },
      {
        path: '/forecast-management',
        name: 'ForecastMeasurementLoading',
        component: ForecastEnergy
      },
      {
        path: '/forecast-validation',
        name: 'ForecastMeasurementValidation',
        component: ForecastValidationEnergy
      },
      {
        path: '/forecast-analysis',
        name: 'ForecastMeasurementAnalysis',
        component: ForecastAnalsisEnergy
      },
      {
        path: '/forecast-import',
        name: 'ForecastMeasurementImportDashboard',
        component: ForecastImportEnergy
      },
      {
        path: '/forecast-import/:id',
        name: 'ForecastMeasurementImportDetail',
        component: ForecastImportDetailEnergy
      },
      {
        path: '/profile',
        name: 'Profile',
        component: Profile
      }
    ]
  },
  {
    path: '/auth/login',
    name: 'Login',
    component: Login
  }
]

const waterRoutes = [
  {
    path: '/',
    component: Private,
    children: [
      {
        path: '/',
        redirect: '/balance'
      },
      {
        path: '/balance-energy',
        component: BalanceDashboardEnergy
      },
      {
        path: '/balance-water',
        component: BalanceDashboardWater
      },
      {
        path: '/balance',
        name: 'BalanceDashboard',
        component: BalanceDashboardWater
      },
      {
        path: '/strategic-plan',
        name: 'BalanceStrategicPlan',
        component: StategicPlanWater
      },
      {
        path: '/strategic-plan/new',
        name: 'PlanNew',
        component: StrategicPlanNewWater
      },
      {
        path: '/strategic-plan/:id',
        name: 'PlanEdit',
        component: StrategicPlanNewWater
      },
      {
        path: '/strategic-analysis',
        name: 'StrategicAnalisis',
        component: StrategicPlanAnalisisWater
      },
      {
        path: '/energy-input',
        name: 'BalanceEnergyInput',
        component: BalanceWaterInput
      },
      {
        path: '/energy-historical',
        name: 'BalanceEnergyHistorical',
        component: BalanceWaterHistorical
      },
      {
        path: '/users',
        component: UserDashboard,
        name: 'UserDashboard'
      },
      {
        path: '/users/new',
        name: 'UserNew',
        component: UserEdit
      },
      {
        path: '/users/:id/change-password',
        name: 'UserChangePassword',
        component: UserChangePassword
      },
      {
        path: '/users/:id',
        name: 'UserEdit',
        component: UserEdit
      },
      {
        path: '/lovs',
        name: 'LovDashboard',
        component: LovWater
      },
      {
        path: '/lovs/new',
        name: 'LovNew',
        component: LovNew
      },
      {
        path: '/lovs/:id',
        name: 'LovEdit',
        component: LovNew
      },
      {
        path: '/brandMaintenance',
        name: 'BrandDashboard',
        component: BrandWater
      },
      {
        path: '/brandMaintenance/new',
        name: 'BrandNew',
        component: BrandNewWater
      },
      {
        path: '/brandMaintenance/:id',
        name: 'BrandEdit',
        component: BrandNewWater
      },
      {
        path: '/network-elements',
        name: 'NetworkElementDashboard',
        component: NetworkElementWater
      },
      {
        path: '/network-elements/:id',
        name: 'NetworkElementEdit',
        component: NetowrkElementNewWater
      },
      {
        path: '/network-config',
        name: 'NetworkDashboard',
        component: NetworkWater
      },
      {
        path: '/import-network',
        name: 'ImportDashboard',
        component: NetworkImportWater
      },
      {
        path: '/import-network/:id',
        name: 'ImportDetail',
        component: NetworkImportDetailWater
      },
      {
        path: '/maneuver',
        name: 'ManeuverDashboard',
        component: ManeuverWater
      },
      {
        path: '/maneuver/:id',
        name: 'ManeuverDetail',
        component: ManeuverDetailWater
      },
      {
        path: '/reconfiguration',
        name: 'ReconfigurationDashboard',
        component: ReconfigurationWater
      },
      {
        path: '/network-history',
        name: 'NetworkHistoryDashboard',
        component: NetworkHistoryWater
      },
      {
        path: '/meters-management',
        name: 'MeterManagementDashboard',
        component: MeterWater
      },
      {
        path: '/import-meters',
        name: 'MeterImportDashboard',
        component: MeterImportWater
      },
      {
        path: '/import-meters/:id',
        name: 'MeterImportDetail',
        component: MeterImportDetailWater
      },
      {
        path: '/areas-definition',
        name: 'AreaDefinitionDashboard',
        component: AreaWater
      },
      {
        path: '/areas-definition/new',
        name: 'AreaDefinitionNew',
        component: AreaNewWater
      },
      {
        path: '/areas-definition/:id',
        name: 'AreaDefinitionEdit',
        component: AreaNewWater
      },
      {
        path: '/periods-maintenance',
        name: 'PeriodDashboard',
        component: PeriodWater
      },
      {
        path: '/periods-maintenance/new',
        name: 'PeriodNew',
        component: PeriodNewWater
      },
      {
        path: '/periods-maintenance/:id',
        name: 'PeriodEdit',
        component: PeriodNewWater
      },
      {
        path: '/purchases',
        name: 'PurchaseDashboard',
        component: PurchaseWater
      },
      {
        path: '/purchases/new',
        name: 'PurchaseNew',
        component: PurchaseNewWater
      },
      {
        path: '/purchases/:id',
        name: 'PurchaseEdit',
        component: PurchaseNewWater
      },
      {
        path: '/measurement-management',
        name: 'MeasurementLoading',
        component: MeasurementWater
      },
      {
        path: '/measurement-validation',
        name: 'MeasurementValidation',
        component: MeasurementValidationWater
      },
      {
        path: '/measurement-bulk-validation',
        name: 'MeasurementBulkValidation',
        component: MeasurementBulkValidationWater
      },
      {
        path: '/measurement-analysis',
        name: 'MeasurementAnalysis',
        component: MeasurementAnalisisWater
      },
      {
        path: '/measurement-import',
        name: 'MeasurementImportDashboard',
        component: MeasurementImportWater
      },
      {
        path: '/measurement-import/:id',
        name: 'MeasurementImportDetail',
        component: MeasurementImportDetailWater
      },
      {
        path: '/forecast-management',
        name: 'ForecastMeasurementLoading',
        component: ForecastWater
      },
      {
        path: '/forecast-validation',
        name: 'ForecastMeasurementValidation',
        component: ForecastValidationWater
      },
      {
        path: '/forecast-analysis',
        name: 'ForecastMeasurementAnalysis',
        component: ForecastAnalisisWater
      },
      {
        path: '/forecast-import',
        name: 'ForecastMeasurementImportDashboard',
        component: ForecastImportWater
      },
      {
        path: '/forecast-import/:id',
        name: 'ForecastMeasurementImportDetail',
        component: ForecastImportDetailWater
      },
      {
        path: '/profile',
        name: 'Profile',
        component: Profile
      }
    ]
  },
  {
    path: '/auth/login',
    name: 'Login',
    component: Login
  }
]

Vue.use(Router)
let module = _.get(storage.get(), 'userModule.data.module') === 1 ? 'Energy' : 'Water'

const route = new Router({
  mode: 'history',
  routes: module === 'Energy' ? energyRoutes : waterRoutes

})

route.afterEach(() => {
  if(localStorage.getItem('reload') === 'true') {
    console.log(localStorage.getItem('reload'),"RELOAD PLIS")
    localStorage.setItem("reload", false);
    window.location.reload()

  }
})

route.beforeEach((to, from, next) => {
  const loggedIn = !!_.get(storage.get(), 'user.data.access_token')
  
  if (to.name !== 'Login' && !loggedIn) next({ name: 'Login' })
  else {
    module = _.get(storage.get(), 'userModule.data.module') === 1 ? 'Energy' : 'Water'
    next()
  }
})

export default route
