import api from '@/config/Api'

const baseEndpoint = '/network/network-unit'
const baseEndpoint2 = '/network/network-unit-water'

export default {
  updateNetworkUnit (id, data) {
    return api.put(`${baseEndpoint}/${id}`, data)
  },

  updateNetworkUnitWater (id, data) {
    return api.put(`${baseEndpoint2}/${id}`, data)
  }
}
