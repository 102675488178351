<template>
  <section v-loading="loading">
    <ods-module>

      <template slot="header">
          <ods-row :gutter="20" type="flex">
            <ods-col :md="5">
              {{ $t('balanceDashboard') }}
            </ods-col>
            <ods-col :md="19" v-if="hasPermission" style="text-align: right">
              <ods-button size="small" icon="ods-icon-iniciatives" type="secondary" @click="toggleLogDialog">
                {{ $t('balanceLog') }}
              </ods-button>
              <ods-button size="medium" @click="startBalance">
              {{ $t('dailyBalance') }}
              </ods-button>
              <ods-button size="medium" @click="startPeriodCalculation" type="success">
                {{ $t('periodCalculation') }}
              </ods-button>
              <ods-button size="medium" @click="startMonetaryCalculation" type="warning">
                {{ $t('monetaryCalculation') }}
              </ods-button>
            </ods-col>
          </ods-row>
      </template>

      <ods-form :model="search" ref="form">
        <ods-row :gutter="20">
          <ods-col :md="6">
            <ods-form-item :label="$t('period')" prop="period">
              <ods-select v-model="search.period" placeholder="" @change="loadData">
                <ods-option
                    v-for="item in periods"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>

          <ods-col :md="18" style="text-align: right">
            <ods-form-item label="" prop="showCurrentBalances" class="mt-1">
              <ods-button size="medium" @click="loadData" type="primary" :disabled="search.period === undefined ? true : false">
                <ods-icon name = "refresh"  style="margin-left:-10px; display:inherit; float:left; padding-right:10px"  size="18"/>{{ $t('refresh') }}
              </ods-button>
              <ods-checkbox style="padding-left:20px"
                  v-model="showCurrentBalances"
                  :label="$t('currentBalances')">
              </ods-checkbox>
            </ods-form-item>
          </ods-col>
        </ods-row>
      </ods-form>

      <ods-table
          :data="tableData"
          size="mini"
          @selection-change="handleSelectionChange"
      >
        <ods-table-column type="selection" width="55"/>
        <ods-table-column prop="area.name" :label="$t('name')"/>
        <ods-table-column prop="status" :label="$t('status')">
          <template slot-scope="scope">
            <div :style="{'background-color': scope.row.status}" style="width: 28px; height: 28px;border-radius: 14px"/>
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('input')" width="150">
          <template slot-scope="scope">
            <div style="color:red" v-if="scope.row.totalEnergyInput <-0.5" @mouseover="showInvalid = true" @mouseleave="showInvalid = false">
                  <div v-if="!showInvalid">
                    {{ $t('invalidData')}}
                  </div>
                    <div v-else>
                    {{ scope.row.totalEnergyInput | formatNumberNoDecimals }}
                  </div>
            </div>
            <div v-else>
                  {{ scope.row.totalEnergyInput | formatNumberNoDecimals }}
            </div>
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('output')" width="150">
          <template slot-scope="scope">
            <div style="color:red" v-if="scope.row.totalEnergyOutput <-0.5" @mouseover="showInvalid = true" @mouseleave="showInvalid = false">
                  <div v-if="!showInvalid">
                    {{ $t('invalidData')}}
                  </div>
                    <div v-else>
                    {{ scope.row.totalEnergyOutput | formatNumberNoDecimals }}
                  </div>
            </div>
            <div v-else>
                  {{ scope.row.totalEnergyOutput | formatNumberNoDecimals }}
            </div>
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('losses')" width="150">
          <template slot-scope="scope">
            <div v-if="showCurrentBalances">
               <div style="color:red" v-if="scope.row.energyLossesRevKwh <-0.5" @mouseover="showInvalid = true" @mouseleave="showInvalid = false">
                  <div v-if="!showInvalid">
                    {{ $t('invalidData')}}
                  </div>
                    <div v-else>
                    {{ scope.row.energyLossesRevKwh | formatNumberNoDecimals }}
                  </div>
              </div>
              <div v-else>
                  {{ scope.row.energyLossesRevKwh | formatNumberNoDecimals }}
              </div>
            </div>
            <div v-else>
               <div style="color:red" v-if="scope.row.energyLossesOriginKwh <-0.5" @mouseover="showInvalid = true" @mouseleave="showInvalid = false">
                  <div v-if="!showInvalid">
                    {{ $t('invalidData')}}
                  </div>
                    <div v-else>
                    {{ scope.row.energyLossesOriginKwh | formatNumberNoDecimals }}
                  </div>
              </div>
              <div v-else>
                  {{ scope.row.energyLossesOriginKwh | formatNumberNoDecimals }}
              </div>
            </div>
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('lossesPercent')" width="150">
          <template slot-scope="scope">
            <div v-if="showCurrentBalances">
              <div style="color:red" v-if="scope.row.energyLossesRevPercent <-0.5" @mouseover="showInvalid = true" @mouseleave="showInvalid = false">
               <div v-if="!showInvalid">
                {{ $t('invalidData')}}
               </div>
                <div v-else>
                 {{ scope.row.energyLossesRevPercent | formatNumberNoDecimals }}%
               </div>
              </div>
              <div v-else>
                 {{ scope.row.energyLossesRevPercent | formatNumberNoDecimals }}%
              </div>
            </div>
            <div v-if="!showCurrentBalances">
              <div style="color:red" v-if="scope.row.energyLossesOriginPercent <-0.5" @mouseover="showInvalid = true" @mouseleave="showInvalid = false">
               <div v-if="!showInvalid">
                {{ $t('invalidData')}}
               </div>
                <div v-else>
                 {{ scope.row.energyLossesOriginPercent | formatNumberNoDecimals }}%
               </div>
              </div>
              <div v-else>
                 {{ scope.row.energyLossesOriginPercent | formatNumberNoDecimals }}%
              </div>
            </div>
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('lastMonthLosses')" width="150">
          <template slot-scope="scope">
            <div v-if="showCurrentBalances">
               <div style="color:red" v-if="scope.row.lastMonth.energyLossesRevKwh <-0.5" @mouseover="showInvalid = true" @mouseleave="showInvalid = false">
                  <div v-if="!showInvalid">
                    {{ $t('invalidData')}}
                  </div>
                    <div v-else>
                    {{ scope.row.lastMonth.energyLossesRevKwh | formatNumberNoDecimals }}
                  </div>
              </div>
              <div v-else>
                  {{ scope.row.lastMonth.energyLossesRevKwh | formatNumberNoDecimals }}
              </div>
            </div>
            <div v-else>
               <div style="color:red" v-if="scope.row.lastMonth.energyLossesOriginKwh <-0.5" @mouseover="showInvalid = true" @mouseleave="showInvalid = false">
                  <div v-if="!showInvalid">
                    {{ $t('invalidData')}}
                  </div>
                    <div v-else>
                    {{ scope.row.lastMonth.energyLossesOriginKwh | formatNumberNoDecimals }}
                  </div>
              </div>
              <div v-else>
                  {{ scope.row.lastMonth.energyLossesOriginKwh | formatNumberNoDecimals }}
              </div>
            </div>
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('lastMonthLossesPercent')" width="150" >
          <template slot-scope="scope">
            <div v-if="showCurrentBalances">
               <div style="color:red" v-if="scope.row.lastMonth.energyLossesRevPercent <-0.5" @mouseover="showInvalid = true" @mouseleave="showInvalid = false">
                  <div v-if="!showInvalid">
                    {{ $t('invalidData')}}
                  </div>
                    <div v-else>
                    {{ scope.row.lastMonth.energyLossesRevPercent | formatNumberNoDecimals }}%
                  </div>
              </div>
              <div v-else>
                  {{ scope.row.lastMonth.energyLossesRevPercent | formatNumberNoDecimals }}%
              </div>
            </div>
            <div v-else>
               <div style="color:red" v-if="scope.row.lastMonth.energyLossesOriginPercent <-0.5" @mouseover="showInvalid = true" @mouseleave="showInvalid = false">
                  <div v-if="!showInvalid">
                    {{ $t('invalidData')}}
                  </div>
                    <div v-else>
                    {{ scope.row.lastMonth.energyLossesOriginPercent | formatNumberNoDecimals }}%
                  </div>
              </div>
              <div v-else>
                  {{ scope.row.lastMonth.energyLossesOriginPercent | formatNumberNoDecimals }}%
              </div>
            </div>
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('monetary')" width="150">
          <template slot-scope="scope">
            <div style="color:red" v-if="scope.row.monetary <-0.5" @mouseover="showInvalid = true" @mouseleave="showInvalid = false">
                  <div v-if="!showInvalid">
                    {{ $t('invalidData')}}
                  </div>
                    <div v-else>
                    {{ scope.row.monetary | formatNumberNoDecimals }}
                  </div>
            </div>
            <div v-else>
                  {{ scope.row.monetary | formatNumberNoDecimals }}
            </div>
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('customers')">
          <template slot-scope="scope">
            <ods-button type="text" class="p-0" @click="toggleDialog(scope.row.area.id,scope.row.area.name,scope.row.totalEnergyOutput)">
              <ods-icon name = "user" size="30" />
            </ods-button>
          </template>
        </ods-table-column>
      </ods-table>
    </ods-module>

    <ods-module class="mt-1" v-if="selectedItems.length">
      <div id="chart" style="height:400px;"></div>
    </ods-module>

    <Modal v-if="dialogVisible"
      :tableDataCustomer = tableDataCustomer
      :areaName = areaName
      :periodId = search.period
      :areaId = toShow
      :output =  output
      :total = total
      v-show="dialogVisible"
      @customers = "customers"
      @close="closeModal"
    />
    <ModalLog v-if="dialogLogVisible"
      :periodId = search.period
      @close="closeLogModal"
    />
  </section>
</template>

<script>
import Modal from './Modal'
import ModalLog from './ModalLog'
import PeriodService from '@/services/Period'
import BalanceService from '@/services/Balance'
import handlePromise from '@/utils/promise'
import * as echarts from 'echarts'
import _ from 'lodash'
import { mapState } from 'vuex'
export default {
  name: 'MeasurementAnalysis',
  components: {
    Modal,
    ModalLog
  },
  mounted () {
    this.fetchPeriods()
    this.handlePermission()
  },
  computed: {
    ...mapState({
      userRole: state => state.userRoles.data
    })
  },
  data () {
    return {
      showInvalid: false,
      autoresize: true,
      hasPermission: true,
      loading: false,
      tableData: [],
      tableDataCustomer: [],
      periods: [],
      search: {},
      dialogVisible: false,
      dialogLogVisible: false,
      showCurrentBalances: false,
      showBalanceLog: false,
      selectedItems: [],
      chart: null,
      toShow: null,
      output: null,
      total: null,
      areaName: '',
      chartOptions: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        xAxis: {
          data: []
        },
        yAxis: [],
        series: []
      }
    }
  },
  watch: {
    showCurrentBalances: function () {
      this.chartOptions = {
        ...this.chartOptions,
        series: [
          {
            name: 'Losses %',
            type: 'bar',
            data: _.map(this.selectedItems, this.showCurrentBalances ? 'energyLossesRevPercent' : 'energyLossesOriginPercent'),
            itemStyle: { color: '#2E6C99' }
          },
          {
            name: 'Last month losses %',
            type: 'bar',
            data: _.map(this.selectedItems, this.showCurrentBalances ? 'energyLossesRevPercent' : 'energyLossesOriginPercent'),
            itemStyle: { color: '#FFAB20' }
          }
        ]
      }
      if (this.chartOptions.yAxis.length > 0) {
        this.chart.setOption(this.chartOptions)
      }
    }
  },
  methods: {
    async fetchPeriods () {
      this.toggleLoading()
      const params = {
        limit: 0
      }
      params['sortby[initialDate]'] = 'DESC'
      const [, response, responseData] = await handlePromise(PeriodService.getPeriods(params))
      this.toggleLoading()
      if (response.ok) this.periods = responseData.data
    },
    async loadData () {
      this.toggleLoading()
      const [, response, responseData] = await handlePromise(BalanceService.getBalancePeriodCalculations(this.search.period))
      this.toggleLoading()
      if (response.ok) {
        this.tableData = responseData
      }
    },
    async startBalance () {
      this.toggleLoading()
      const [error, response] = await handlePromise(BalanceService.startDailyBalance())
      this.toggleLoading()
      this.showBalanceLog = true
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))

      return this.$store.commit('settings/toggleSuccessAlert', this.$t('dailyBalanceStarted'))
    },
    async startPeriodCalculation () {
      this.showBalanceLog = false
      this.toggleLoading()
      const [error, response] = await handlePromise(BalanceService.startPeriodBalance())
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))

      return this.$store.commit('settings/toggleSuccessAlert', this.$t('periodCalculationStarted'))
    },
    async startMonetaryCalculation () {
      this.showBalanceLog = false
      this.toggleLoading()
      if (!this.search.period) {
        this.toggleLoading()
        return this.$store.commit('settings/toggleAlert', this.$t('selectPeriod'))
      }
      const [error, response] = await handlePromise(BalanceService.monetaryCalculation(this.search.period))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      return this.$store.commit('settings/toggleSuccessAlert', this.$t('periodCalculationStarted'))
    },
    async toggleDialog (id, name, output) {
      this.toShow = id
      this.areaName = name
      this.output = output
      if (id) await this.getMeters()
      this.dialogVisible = true
    },
    closeModal () {
      this.dialogVisible = false
    },
    closeLogModal () {
      this.dialogLogVisible = false
    },
    customers (event) {
      this.total = event[0].total
      this.tableDataCustomer = []
      event[1].map(item => {
        this.tableDataCustomer.push(item)
      })
    },
    async getMeters () {
      this.toggleLoading()
      const [error, response, data] = await handlePromise(BalanceService.getBalanceCustomersByArea(this.toShow, this.search.period))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.total = data[0].total
      this.tableDataCustomer = data[1]
    },
    loadChartData () {
      if (this.selectedItems.length) {
        this.chart = echarts.init(document.getElementById('chart'))
        this.chartOptions = {
          ...this.chartOptions,
          yAxis: [{
            type: 'value',
            name: 'Losses'
          }],
          xAxis: {
            data: _.map(this.selectedItems, 'area.name')
          },
          series: [
            {
              name: 'Losses %',
              type: 'bar',
              data: _.map(this.selectedItems, this.showCurrentBalances ? 'energyLossesRevPercent' : 'energyLossesOriginPercent'),
              itemStyle: { color: '#2E6C99' }
            },
            {
              name: 'Last month losses %',
              type: 'bar',
              data: _.map(this.selectedItems, this.showCurrentBalances ? 'lastMonth.energyLossesRevPercent' : 'lastMonth.energyLossesOriginPercent'),
              itemStyle: { color: '#FFAB20' }
            }
          ]
        }
        this.chart.setOption(this.chartOptions)
      }
    },
    handleSelectionChange (val) {
      this.selectedItems = val
      setTimeout(this.loadChartData, 200)
    },
    toggleLoading () {
      this.loading = !this.loading
    },
    toggleLogDialog () {
      if (this.search.period) {
        this.dialogLogVisible = !this.dialogLogVisible
      } else {
        return this.$store.commit('settings/toggleAlert', this.$t('selectPeriod'))
      }
    },
    handlePermission () {
      let rolesArray = Object.values(this.userRole)
      rolesArray.map(role => {
        if (role === 'ROLE_OPERATOR') {
          this.hasPermission = false
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_SUPER_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_MANAGER') {
              this.hasPermission = true
            }
          })
        }
      })
    }
  }
}
</script>
<style scoped>
 .invalid {
   color: rgb(255, 0, 0);
 }
</style>
