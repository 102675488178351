<template>
  <section v-loading="loading">
    <ods-row :gutter="20" type="flex">
      <ods-col :md="7">
        <network-tree @node-click="loadNodeData" @toggle-loading="toggleLoading" ref="treeComp">
        </network-tree>
      </ods-col>

      <ods-col :md="11" v-if="nodeForm.id">
        <ods-module>
          <template slot="header">
            <ods-row :gutter="20">
              <ods-col :md="6">
                {{ $t('network') }}
              </ods-col>
              <br>
              <ods-col :md="24" class="text-right" v-if="!nodeForm.disabled">
                <ods-button type="secondary" size="small" @click="toggleAddGeneratorDialog">
                  {{ $t('newFirstLevelNode') }}
                </ods-button>
                <ods-button type="secondary" v-if="hasPermission" size="small" @click="toggleAddNodeDialog">
                  {{ $t('addChild') }}
                </ods-button>
                <ods-button type="warning" size="small" @click="toggleMoveNodeDialog"
                  v-if="nodeForm && nodeForm.extraData && !nodeForm.extraData.networkTree && hasPermission">
                  {{ $t('move') }}
                </ods-button>
                <ods-button v-if="hasPermission" type="danger" size="small" @click="toggleDeleteDialog">
                  {{ $t('delete') }}
                </ods-button>
              </ods-col>
            </ods-row>
          </template>

          <edit-node :hasPermission="hasPermission" :model="nodeForm"></edit-node>
        </ods-module>
      </ods-col>

      <ods-col :md="11" v-if="!nodeForm.id" style="min-height: 100%;">
        <ods-module :header="$t('network')" style="min-height: 100%">
          <template slot="header">
            <ods-row :gutter="20">
              <ods-col :md="6">
                {{ $t('network') }}
              </ods-col>
              <ods-col :md="18" class="text-right" v-if="hasPermission">
                <ods-button type="secondary" size="small" @click="toggleAddGeneratorDialog">
                  {{ $t('newFirstLevelNode') }}
                </ods-button>
                <!--<ods-button type="danger" size="small" @click="toggleDeleteDialog">
                  {{$t('delete')}}
                </ods-button>-->
              </ods-col>
            </ods-row>
          </template>
          <ods-row type="flex" justify="center" style="margin-top: 2rem">
            <ods-icon name="info" size="100" class="custom-placeholder" />
          </ods-row>
        </ods-module>
      </ods-col>

      <ods-col :md="6">
        <ods-module class="pt-1 mb-1">
          <span class="font-weight-bold mb-1">{{ $t('colorsLegend').toUpperCase() }}</span>
          <div>
            <ods-icon name="lock" size="15" style="margin-right: 5px; color: #2E6C99" />{{ $t('nodeBlocked') }}
          </div>

          <div class="flex-center" style="justify-content: space-between">
            <div class="flex-center">
              <ods-icon name="legend-point" size="20" style="margin-right: 5px;color: #36B348" />{{ $t('nodeCreated') }}
            </div>
            <div class="flex-center">
              <ods-icon name="legend-point" size="20" style="margin-right: 5px;color: #2E6C99" />{{ $t('nodeMoved') }}
            </div>
            <div class="flex-center">
              <ods-icon name="legend-point" size="20" style="margin-right: 5px;color: #DE2F2F" />{{ $t('nodeDeleted') }}
            </div>
          </div>
        </ods-module>
        <ods-module class="pt-1" v-if="map">
          <div class="google-map" ref="googleMap" style="height: 200px">
          </div>
        </ods-module>

        <ods-module class="pt-1" v-bind:class="{ 'mt-1': !!map }" v-if="logs && logs.id">
          <network-logs :data="logs" show-actions @toggle-loading="toggleLoading" @reload="reloadData"></network-logs>
        </ods-module>
      </ods-col>


    </ods-row>

    <add-node v-if="nodeForm.id" :visible="addNodeDialogVisible" :parent="nodeForm.extraData.id"
      @cancel="toggleAddNodeDialog" @reload="reloadNodeAdded"></add-node>

    <add-generator :visible="addGeneratorDialogVisible" @cancel="toggleAddGeneratorDialog"
      @reload="reloadGeneratorAdded"></add-generator>

    <move-node v-if="nodeForm.id" :visible="moveNodeDialogVisible" :from="nodeForm.extraData"
      @cancel="toggleMoveNodeDialog" @reload="reloadNodeMoved"></move-node>

    <dialog-confirmation :visible="deleteDialogVisible" :loading="deleteLoading" @confirm="deleteNode"
      @cancel="toggleDeleteDialog" />
  </section>
</template>

<script>
import GoogleMapsApiLoader from 'google-maps-api-loader'
import get from 'lodash/get'
import { mapState } from 'vuex'
import NetworkNodeService from '@/services/NetworkNode'
import NetwortManeuverService from '@/services/NetwortManeuver'
import handlePromise from '@/utils/promise'
import errors from '@/config/InputErrors'
import DialogConfirmation from '@/custom-components/DialogConfirmation'
import AddGenerator from './AddGenerator.vue'
import AddNode from './AddNode'
import EditNode from './EditNode'
import MoveNode from './MoveNode'
import NetworkTree from '@/custom-components/Network/Tree'
import NetworkLogs from '@/custom-components/Network/Logs'
import attributes from '@/config/data/Attributes'
import _ from 'lodash'
import env from 'my-env'

export default {
  name: 'NetworkDashboard',
  components: {
    NetworkTree,
    AddNode,
    AddGenerator,
    NetworkLogs,
    EditNode,
    MoveNode,
    DialogConfirmation
  },
  data() {
    return {
      hasPermission: true,
      loading: false,
      nodeForm: {},
      logs: null,
      addGeneratorDialogVisible: false,
      addNodeDialogVisible: false,
      moveNodeDialogVisible: false,
      errors,
      google: null,
      map: null,
      deleteLoading: false,
      deleteDialogVisible: false
    }
  },
  computed: {
    ...mapState({
      userData: state => state.user.data,
      userRole: state => state.userRoles.data
    })
  },
  async mounted() {
    this.handlePermission()
    this.google = await GoogleMapsApiLoader({
      apiKey: env.VUE_APP_MAP_API_KEY
    })
    this.loadNodeManeuvers()
  },
  methods: {
    async loadNodeData({ id }, loading = true) {
      if (loading) this.toggleLoading()
      const [error, response, data] = await handlePromise(NetworkNodeService.getNode(id))
      if (!response.ok) return this.$store.commit('settings/toggleAlert', error)

      /* if (get(data, 'networkUnit.networkElement.id')) {
                  const [errorAttr, responseAttr, dataAttr] = await handlePromise(
                    NetworkElementService.getElementAttributes(get(data, 'networkUnit.networkElement.id'), { limit: -1 }))
                  if (!responseAttr.ok) return this.$store.commit('settings/toggleAlert', errorAttr)

                  attributes = dataAttr.data.map(attr => {
                    const attributeValue = find(get(data, 'networkUnit.attributesValues', []), { attribute: { id: attr.id } })
                    return {
                      ...attr,
                      value: attributeValue ? attributeValue.value : null
                    }
                  })
                } */

      await this.loadNodeManeuvers()

      if (loading) this.toggleLoading()
      if (data.networkUnit) {
        const attributeValues = attributes.map(attr => ({
          ...attr,
          value: data.networkUnit[attr.name]
        }))

        this.nodeForm = {
          id: data.networkUnit.id,
          name: data.networkUnit.name,
          technicalLosses: data.networkUnit.technicalLosses,
          originSystemId: data.networkUnit.originSystemId,
          networkElement: `${data.networkUnit.networkElement.name} ${data.networkUnit.networkElement.elementType}`,
          attributes: attributeValues,
          extraData: data,
          disabled: get(data, 'parentNetworkTree.blocked') &&
            (get(data, 'parentNetworkTree.blockedBy') !== get(this.userData, 'user.id') ||
              (data.status === 'created' || data.status === 'deleted' || data.status === 'modified')),
          disabledAttributes: get(data, 'parentNetworkTree.blocked') &&
            get(data, 'parentNetworkTree.blockedBy') !== get(this.userData, 'user.id')
        }
      } else {
        this.nodeForm = {}
      }

      if (data.networkUnit.geometry) {
        this.map = {}
        setTimeout(() => {
          this.initializeMap(data.networkUnit.geometry)
        }, 100)
      }
    },
    async loadNodeManeuvers() {
      const [, response, data] = await handlePromise(NetwortManeuverService.getMyManeuvers())
      if (response.ok) {
        _.map(data.logNetworkChanges, change => {
          if (change.action === 'modified') {
            const secondMov = _.find(data.logNetworkChanges,
              item => item.id !== change.id && item.refModified === change.refModified && !change.mapped
            )
            if (!secondMov) return change
            if (change.origin) {
              change.destination = { ...secondMov.destination }
              change.destinationTree = { ...secondMov.networkTree }
            } else {
              change.origin = { ...secondMov.origin }
              change.originTree = { ...secondMov.networkTree }
            }
            secondMov.mapped = true
          }
        })
        data.logNetworkChanges = _.filter(data.logNetworkChanges, item => !item.mapped)
        this.logs = data
      }
    },
    async deleteNode() {
      this.deleteLoading = true
      const [error, response] = await handlePromise(NetworkNodeService.deleteNode(this.nodeForm.extraData.id))
      this.deleteLoading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', error)

      this.nodeForm = {
        ...this.nodeForm,
        disabled: true
      }
      this.reloadData()
      this.$store.commit('settings/toggleSuccessAlert', this.$t('nodeSaved'))
      this.toggleDeleteDialog()
    },
    initializeMap(marker) {
      const position = { lat: marker.coordinates[0], lng: marker.coordinates[1] }
      const mapContainer = this.$refs.googleMap
      this.map = new this.google.maps.Map(mapContainer, { center: position, zoom: 8 })
      // eslint-disable-next-line no-new
      new this.google.maps.Marker({
        position,
        map: this.map
      })
    },
    toggleLoading() {
      this.loading = !this.loading
    },
    toggleAddNodeDialog() {
      this.addNodeDialogVisible = !this.addNodeDialogVisible
    },
    toggleAddGeneratorDialog() {
      this.addGeneratorDialogVisible = !this.addGeneratorDialogVisible
    },
    toggleMoveNodeDialog() {
      this.moveNodeDialogVisible = !this.moveNodeDialogVisible
    },
    toggleDeleteDialog() {
      this.deleteDialogVisible = !this.deleteDialogVisible
    },
    async reloadData() {
      this.$refs.treeComp.$refs.tree.reload()
      if (this.nodeForm.id) {
        this.loadNodeData({ id: this.nodeForm.extraData.id }, false)
      } else {
        await this.loadNodeManeuvers()
      }
    },
    reloadNodeAdded() {
      this.toggleAddNodeDialog()
      this.reloadData()
      this.$store.commit('settings/toggleSuccessAlert', this.$t('nodeSaved'))
    },
    reloadGeneratorAdded() {
      this.toggleAddGeneratorDialog()
      this.loadNodeManeuvers()
      this.$store.commit('settings/toggleSuccessAlert', this.$t('generatorSaved'))
    },
    reloadNodeMoved() {
      this.toggleMoveNodeDialog()
      this.reloadData()
      this.$store.commit('settings/toggleSuccessAlert', this.$t('nodeSaved'))
    },
    handlePermission() {
      let rolesArray = Object.values(this.userRole)
      rolesArray.map(role => {
        if (role === 'ROLE_OPERATOR') {
          this.hasPermission = false
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_SUPER_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_MANAGER') {
              this.hasPermission = true
            }
          })
        }
      })
    }
  }
}
</script>
